/* eslint-disable no-underscore-dangle */
import LayoutMixinHelper from './layout-mixin-helper';

export default {
    name: 'mwc-layout-mixin',
    created() {
        // Work with a copy of the layout setting in case the component needs to update it
        if (this.settings && this.settings._layout) {
            let layout;
            if (this.settings._layout instanceof Array) {
                layout = JSON.parse(JSON.stringify(this.settings._layout.slice(0)));
            } else if (typeof this.settings._layout === 'string') {
                layout = this.settings._layout;
            } else {
                layout = Object.assign({}, this.settings._layout);
            }
            this.options = this.options || {};
            this.options._layout = layout;
        }
    },
    mounted() {
        this.layoutMixinHelper = new LayoutMixinHelper(this.mwcId, this.$style);
        this.applyLayouts();
    },
    computed: {
        parsedLayout() {
            let result = [];
            const layout = this.options && this.options._layout;
            if (layout) {
                result = typeof layout === 'string' ? JSON.parse(layout) : layout;
                if (!(result instanceof Array)) {
                    // If it's not an array assume it's in the old format, so convert it
                    result = this.layoutMixinHelper.convertLayout(result);
                }
            }
            return result;
        },
    },
    methods: {
        applyGroups(elem, layout) {
            // Loop through the layout and check for groups
            layout.rows.forEach(row => {
                if (row.groups) {
                    row.sections = [];
                    row.groups.forEach(group => {
                        if (group.sections) {
                            // Create an accordion for each group of sections...
                            const accordionId = this.createGroup(elem, group, layout.exclusiveGroups);
                            // ...and then specify this new accordion element as a section in the layout
                            row.sections.push(accordionId);
                        }
                    });
                }
            });
        },
        applyLayouts() {
            this.parsedLayout.forEach(elementLayout => {
                this.applyLayout(elementLayout);
            });
        },
        applyLayout(elementLayout) {
            const elem = this.layoutMixinHelper.getElement(this.$el, elementLayout.elementId);
            if (elem && elementLayout) {
                elem.style.setProperty('display', 'none');
                const apply = () => {
                    elementLayout = JSON.parse(JSON.stringify(elementLayout));
                    this.sortRows(elementLayout);
                    this.defineRows(elementLayout);
                    this.applyGroups(elem, elementLayout);
                    this.renderLayout(elem, elementLayout);
                    elem.style.removeProperty('display');
                };

                if (elementLayout.awaitNextTick === false) {
                    apply();
                } else {
                    this.$nextTick(() => {
                        apply();
                    });
                }
            }
        },
        createGroup(elem, group, exclusiveGroups) {
            let accordionTitle = group.title;
            if (accordionTitle && this.labeller.exists(accordionTitle)) {
                accordionTitle = this.translate(accordionTitle);
            }
            let accordionAriaLabel = group.ariaLabel;
            if (accordionAriaLabel && this.labeller.exists(accordionAriaLabel)) {
                accordionAriaLabel = this.translate(accordionAriaLabel);
            }
            let accordionSummary = group.summary;
            if (accordionSummary && this.labeller.exists(accordionSummary)) {
                accordionSummary = this.translate(accordionSummary);
            }
            return this.layoutMixinHelper.createGroup({
                elem,
                group,
                accordionTitle,
                accordionAriaLabel,
                accordionSummary,
                exclusiveGroups: exclusiveGroups || false,
            });
        },
        defineRows(elemLayout) {
            const getRowDef = row => {
                let rowDef;
                if (typeof row === 'string') {
                    // try to find a matching definition
                    rowDef = elemLayout.rowDefinitions[row];
                } else {
                    rowDef = row;
                    const rowId = row.id;
                    // Merge our row object with an existing definition
                    if (rowId && elemLayout.rowDefinitions[rowId]) {
                        rowDef = Object.assign({}, elemLayout.rowDefinitions[rowId], row);
                    }
                }
                return rowDef;
            };

            // Loop through the rows array and find row definitions
            const rows = [];
            elemLayout.rows.forEach(row => {
                const rowDef = getRowDef(row);
                if (rowDef) {
                    rows.push(rowDef);

                    // Check for nested rows in the sections and define any named rows
                    if (rowDef.sections) {
                        rowDef.sections.forEach(section => {
                            if (section.rows) {
                                const nestedRows = [];
                                section.rows.forEach(nestedRow => {
                                    const nestedRowDef = getRowDef(nestedRow);
                                    if (nestedRowDef) {
                                        nestedRows.push(nestedRowDef);
                                    }
                                });
                                section.rows = nestedRows;
                            }
                        });
                    }
                }
            });
            elemLayout.rows = rows;
        },
        renderLayout(elem, elemLayout) {
            // Get a list of all the child nodes by id
            const nodes = {};
            const getNodeId = n => {
                let id = n.getAttribute('mwc-id');
                if (id) {
                    id = id.split('.').pop();
                } else {
                    id = n.getAttribute('id');
                }
                return id;
            };

            const addToNodes = n => {
                const id = getNodeId(n);
                if (id) {
                    nodes[id] = n;
                }
            };

            const loopThruCols = cols => {
                Object.keys(cols).forEach(colKey => {
                    const col = cols[colKey];
                    // eslint-disable-next-line no-use-before-define
                    findNamedNodes(col.children);
                });
            };

            const findNamedNodes = children => {
                Object.keys(children).forEach(nodeKey => {
                    const node = children[nodeKey];
                    // Check for nested rows
                    if (
                        node.classList.contains('mds-layout-grid__row') ||
                        node.classList.contains(this.layoutMixinHelper.updateClassName('mds-layout-grid__row'))
                    ) {
                        loopThruCols(node.children);
                    } else {
                        addToNodes(node);
                    }
                });
            };

            const loopThruRows = rows => {
                Object.keys(rows).forEach(rowKey => {
                    const row = rows[rowKey];
                    loopThruCols(row.children);
                });
            };

            const searchInGrid = e => {
                // Check for a single top-level element
                if (e.children.length === 1) {
                    return (
                        e.children[0].classList.contains('mds-layout-grid') ||
                        e.children[0].classList.contains(this.layoutMixinHelper.updateClassName('mds-layout-grid'))
                    );
                }
                return false;
            };

            // Check for a layout grid to see if a layout has already been applied
            if (searchInGrid(elem)) {
                const gridElem = elem.children[0];
                loopThruRows(gridElem.children);
            } else {
                findNamedNodes(elem.children);
            }

            // and remove everything from the layout element ready to add specified ones back
            while (elem.firstChild) {
                elem.removeChild(elem.firstChild);
            }

            const grid = this.layoutMixinHelper.createGrid(elemLayout, nodes);
            elem.appendChild(grid);
        },
        sortRows(elemLayout) {
            // Create an array from the rows object (if not an array already) and sort by the order property
            let rowsArray = [];
            if (elemLayout.rows instanceof Array) {
                rowsArray = elemLayout.rows;
            } else {
                Object.keys(elemLayout.rows).forEach(rowKey => {
                    elemLayout.rows.id = rowKey;
                    rowsArray.push(elemLayout.rows[rowKey]);
                });
            }
            rowsArray.sort((a, b) => {
                return (a.order || 1) - (b.order || 1);
            });
            elemLayout.rows = rowsArray;
        },
    },
};
