import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill/dist/ResizeObserver';

export const BREAKPOINTS = [
    { name: 'xsmall', value: 320 }, // MDS default
    { name: 'small', value: 600 },
    { name: 'medium', value: 768 },
    { name: 'large', value: 1000 },
    { name: 'xlarge', value: 1200 },
];

export function findBreakpoint(width = window.innerWidth, breakpoints = BREAKPOINTS) {
    let index = breakpoints.findIndex(b => width <= b.value);
    index = index >= 0 ? index : breakpoints.length - 1;

    return {
        index,
        ...breakpoints[index],
    };
}

/* lodash defaults:
    leading = false
    maxing = false
    trailing = true
*/
const DEBOUNCE_CONFIG = {
    wait: 100,
    options: {
        leading: false,
        maxing: false,
        trailing: true,
    },
};

export default {
    data() {
        return {
            elementWidth: 0,
        };
    },
    /**
     * customize breakpoints or debounce config by setting this.elementBreakpoints and/or
     * this.elementDebounceConfig in beforeCreate hook
     */
    created() {
        this.elementBreakpoints = this.elementBreakpoints || BREAKPOINTS;
        this.elementDebounceConfig = Object.assign({}, this.elementDebounceConfig || {}, DEBOUNCE_CONFIG);
    },
    mounted() {
        this.$_mwc_debouncedOnResize = debounce(
            this.$_mwc_onResize,
            this.elementDebounceConfig.wait,
            this.elementDebounceConfig.options
        );
        this.$_mwc_ro = new ResizeObserver(entries => {
            const entry = entries.find(e => e.target === this.$el);
            const { paddingLeft, paddingRight } = window.getComputedStyle(this.$el);
            const { width } = entry.contentRect;
            if (width) {
                this.$_mwc_debouncedOnResize(width + parseFloat(paddingLeft) + parseFloat(paddingRight));
            }
        });

        this.$_mwc_ro.observe(this.$el);
    },
    beforeDestroy() {
        this.$_mwc_ro.unobserve(this.$el);
        this.$_mwc_ro.disconnect();
    },
    computed: {
        breakpoint() {
            const breakpoint = findBreakpoint(this.elementWidth, this.elementBreakpoints);
            return breakpoint ? breakpoint.name : this.elementBreakpoints.slice(-1)[0].name;
        },
        breakpointClass() {
            if (this.breakpoint === 'xsmall') {
                return '';
            }

            return `${this.namespace || this.$options.name}--${this.breakpoint}`;
        },
    },
    methods: {
        $_mwc_onResize(width) {
            this.elementWidth = width || this.$el.clientWidth;
        },
    },
};
