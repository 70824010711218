import helper from './helper';

export default class LayoutMixinHelper {
    constructor(mwcId, $style) {
        this.mwcId = mwcId;
        this.$style = $style;
    }

    /**
     * Convert from the object style layout format to the array format
     * @param {*} oldLayout
     */
    // eslint-disable-next-line class-methods-use-this
    convertLayout(oldLayout) {
        const newLayout = [];
        Object.keys(oldLayout).forEach(key => {
            newLayout.push({
                elementId: key,
                rows: oldLayout[key].rows,
                rowDefinitions: oldLayout[key].rowDefinitions,
                awaitNextTick: oldLayout[key].awaitNextTick,
            });
        });
        return newLayout;
    }

    /**
     * Update a class name with the project's ident name if set
     */
    updateClassName(className) {
        return this.$style && this.$style[className] ? this.$style[className] : className;
    }

    /**
     * Update an array of class names with the project's ident name if set
     */
    updateClassNames(classNames) {
        if (this.$style) {
            return classNames.map(c => this.$style[c]).filter(c => !!c);
        }
        return classNames;
    }

    /**
     * Get an element reference for the specified id or namespaced id
     * @param {*} parent - element to query
     * @param {*} id - the id of the element to get
     * @returns - the element reference or null if not found
     */
    getElement(parent, id) {
        let elem = parent.querySelector(`#${id}`);
        if (!elem) {
            const namespacedId = helper.getNamespaceId(this.mwcId, id);
            elem = parent.querySelector(`#${namespacedId}`);
        }
        return elem;
    }

    /**
     * Get the mds grid col classes to apply based on the section, row and layout options
     * @param {*} section - section object with layout options
     * @param {*} layout - top-level layout object with layout options
     * @param {*} row - row object with layout options
     */
    getColumnClasses(section, layout, row) {
        let classList = ['mds-layout-grid__col'];
        layout = layout || {};

        if (layout.defaults || row.defaults) {
            section = Object.assign({}, layout.defaults, row.defaults, section);
        }

        if (section.cols) {
            classList.push(`mds-layout-grid__col--${section.cols}`);
        }

        if (section.zeroPadding) {
            row.zeroMargin = true; // Need to apply zero-margin to row for zero-padding to work correctly
            classList.push('mds-layout-grid__col--zero-padding');
        }

        classList = classList.concat(LayoutMixinHelper.getPullPushSqueezeClasses(section));

        // breakpoint overrides
        if (section.breakpoints) {
            Object.keys(section.breakpoints).forEach(breakpointKey => {
                const breakpoint = section.breakpoints[breakpointKey];
                classList = classList.concat(LayoutMixinHelper.getPullPushSqueezeClasses(breakpoint, breakpointKey));
                if (breakpoint.cols) {
                    if (breakpoint.cols === 'auto') {
                        classList.push(`mds-layout-grid__col--auto-at-${breakpointKey}`);
                    } else {
                        classList.push(`mds-layout-grid__col--${breakpoint.cols}-at-${breakpointKey}`);
                    }
                }
            });
        }

        if (section.classList) {
            classList = classList.concat(section.classList);
        }

        classList = this.updateClassNames(classList);
        return classList;
    }

    /**
     * Create an accordion and add sections to be grouped together
     * @param {object} options = group options
     *      elem - the main layout element
     *      group - group object with section options
     */
    createGroup(options) {
        const { elem, group, accordionTitle, accordionAriaLabel, accordionSummary, exclusiveGroups } = options;
        const uniqueId = `${this.mwcId}-${group.id}`.replace(/\./g, '-');
        const accordionId = `mds-accordion-${uniqueId}`;

        const accordion = this.createAccordion({
            accordionId,
            groupId: group.id,
            uniqueId,
            title: accordionTitle,
            ariaLabel: accordionAriaLabel,
            summary: accordionSummary,
        });

        // Expand the group if requested
        const input = accordion.querySelector(`#label-header-${uniqueId}`);
        if (group.expanded) {
            input.checked = true;
            input.setAttribute('aria-expanded', 'true');
        }

        // Toggle the aria-expanded attribute on the input when the accordion is clicked
        accordion.addEventListener('click', e => {
            let expanded = false;
            if (e.target.tagName === 'INPUT') {
                if (input.getAttribute('aria-expanded') === 'false') {
                    input.setAttribute('aria-expanded', 'true');
                    expanded = true;
                } else {
                    input.setAttribute('aria-expanded', 'false');
                }
            }

            // If we expand an accordion then collapse all the others in the same parent
            if (exclusiveGroups && expanded) {
                const accordions = elem.querySelectorAll('.mds-accordion');
                accordions.forEach(a => {
                    if (a.id !== accordion.id) {
                        const accordionInput = a.querySelector('input');
                        if (accordionInput) {
                            accordionInput.checked = false;
                            accordionInput.setAttribute('aria-expanded', 'false');
                        }
                    }
                });
            }
        });

        // Move the sections into the accordion
        if (group.sections) {
            const sectionHolder = accordion.querySelector(`#mds-accordion-group-${uniqueId}`);

            // Do we need a child mds-grid layout for the sections?
            const needsGrid = LayoutMixinHelper.needsGridLayout(group);
            let accordionRow;
            if (needsGrid) {
                accordionRow = this.createAccordionGridRow(sectionHolder);
            }

            group.sections.forEach(section => {
                section = LayoutMixinHelper.convertSection(section);
                const sectionElement = this.getElement(elem, section.id);
                if (sectionElement) {
                    if (needsGrid) {
                        // Create an mds grid column for each section and add to the grid row
                        const classList = this.getColumnClasses(section, null, group);
                        const sectionColumn = document.createElement('div');
                        sectionColumn.className = classList.join(' ');
                        sectionColumn.appendChild(sectionElement);
                        accordionRow.appendChild(sectionColumn);
                    } else {
                        // Add the section element directly to the accordion element
                        sectionHolder.appendChild(sectionElement);
                    }
                }
            });
        }
        // and append it to the layout element
        elem.appendChild(accordion);
        return accordionId;
    }

    /**
     * Create the layout grid based on the layout setting
     * @param {*} layout - the layout setting object
     * @param {*} nodes - node list of the sections to add to the layout
     */
    createGrid(layout, nodes) {
        const rows = [];

        const createRowElement = (row, cols) => {
            let rowClassList = ['mds-layout-grid__row'];
            if (row.zeroMargin) {
                rowClassList.push('mds-layout-grid__row--zero-margin');
            }
            rowClassList = rowClassList.concat(LayoutMixinHelper.getAlignmentClasses(row));
            if (row.classList) {
                rowClassList = rowClassList.concat(row.classList);
            }
            rowClassList = this.updateClassNames(rowClassList);
            return LayoutMixinHelper.createElement(rowClassList, cols);
        };

        const getColumnsArray = row => {
            const cols = [];
            if (row.sections) {
                row.sections.forEach(section => {
                    section = LayoutMixinHelper.convertSection(section);
                    const classList = this.getColumnClasses(section, layout, row);

                    let nodeArray = [];

                    // Do we have nested rows?
                    if (section.rows) {
                        // Add a nested row and columns for each section
                        section.rows.forEach(nestedRow => {
                            const nestedCols = getColumnsArray(nestedRow);
                            const nestedRowElement = createRowElement(nestedRow, nestedCols);
                            // Add this row element to the nodes to be added to the parent column
                            nodeArray.push(nestedRowElement);
                        });
                    } else {
                        // Look for our section by id and namespaced id
                        let node = nodes[section.id];
                        if (!node) {
                            const namespacedId = helper.getNamespaceId(this.mwcId, section.id);
                            node = nodes[namespacedId];
                        }
                        // If found, add the section to the nodes to be added to the parent column
                        if (node) {
                            nodeArray = [node];
                        }
                    }

                    const colElement = LayoutMixinHelper.createElement(classList, nodeArray);
                    cols.push(colElement);
                });
            }
            return cols;
        };

        layout.rows.forEach(row => {
            const cols = getColumnsArray(row);
            if (cols.length > 0) {
                rows.push(createRowElement(row, cols));
            }
        });

        return LayoutMixinHelper.createElement([this.updateClassName('mds-layout-grid')], rows);
    }

    /**
     * Create an accordion element for grouping sections
     * @param {object} options - accordion options
     *      accordionId - the accordion id
     *      groupId - the group id
     *      uniqueId - the unique element id suffix
     *      title - the accordion title
     */
    createAccordion(options) {
        const { accordionId, groupId, uniqueId, title, ariaLabel, summary } = options;
        // Do we need a summary element?
        let summaryElement = '';
        if (summary) {
            summaryElement = `<p class="${this.updateClassName('mds-accordion__summary')}">${summary}</p>`;
        }

        let ariaLabelAttr = '';
        if (ariaLabel) {
            ariaLabelAttr = ` aria-label="${ariaLabel}"`;
        }

        // Create the accordion element
        const accordion = document.createElement('div');
        accordion.setAttribute('id', accordionId);

        const triggerClass = this.updateClassName('mds-accordion__trigger');
        const headerClasses = this.updateClassNames(['mds-header', 'mds-header--secondary', 'mds-header--level-7']);

        accordion.classList.add(
            this.updateClassName('mds-accordion'),
            this.updateClassName(`mds-accordion--${groupId}`)
        );

        accordion.innerHTML = `<input id="label-header-${uniqueId}" type="checkbox" role="button" aria-expanded="false" aria-controls="mds-accordion-group-${uniqueId}" class="${triggerClass}" />
        <label for="label-header-${uniqueId}" class="${this.updateClassName('mds-accordion__label')}">
            <div class="${headerClasses.join(' ')}">
                <h2 class="${this.updateClassName('mds-header__title')}"${ariaLabelAttr}>
                    ${title}
                </h2>
            </div>
        </label>
        <section class="${this.updateClassName('mds-accordion__content')}" id="mds-accordion-group-${uniqueId}">
            ${summaryElement}
        </section>`;
        return accordion;
    }

    /**
     * Create an mds grid and row element in the specified accordion element
     * @param {*} accordion - the accordion element
     * @param {*} id - the accordion id
     */
    createAccordionGridRow(sectionHolder) {
        const accordionGrid = document.createElement('div');
        accordionGrid.className = this.updateClassName('mds-layout-grid');
        const accordionRow = document.createElement('div');
        accordionRow.className = this.updateClassName('mds-layout-grid__row');
        accordionGrid.appendChild(accordionRow);
        sectionHolder.appendChild(accordionGrid);
        return accordionRow;
    }

    /**
     * Create an element with the specified classes and child nodes
     */
    static createElement(classList, childNodes) {
        const d = document.createElement('div');
        d.className = classList.join(' ');
        Object.keys(childNodes).forEach(nodeKey => {
            const n = childNodes[nodeKey];
            d.appendChild(n);
        });
        return d;
    }

    /**
     * Get mds grid row alignment classes based on row options
     * @param {*} row - row object with layout options
     */
    static getAlignmentClasses(row) {
        const classList = [];
        switch (row.horizontalAlign) {
            case 'start':
                classList.push('mds-layout-grid__row--justify-content-flex-start');
                break;
            case 'center':
                classList.push('mds-layout-grid__row--justify-content-center');
                break;
            case 'end':
                classList.push('mds-layout-grid__row--justify-content-flex-end');
                break;
            case 'around':
                classList.push('mds-layout-grid__row--justify-content-space-around');
                break;
            case 'between':
                classList.push('mds-layout-grid__row--justify-content-space-between');
                break;
            default:
                break;
        }
        switch (row.verticalAlign) {
            case 'top':
                classList.push('mds-layout-grid__row--align-items-flex-start');
                break;
            case 'center':
                classList.push('mds-layout-grid__row--align-items-center');
                break;
            case 'bottom':
                classList.push('mds-layout-grid__row--align-items-flex-end');
                break;
            default:
                break;
        }
        return classList;
    }

    /**
     * Add mds grid col pull/push/squeeze classes to the specified class list
     * @param {*} breakpoint - breakpoint object with layout options
     * @param {*} breakpointKey - breakpoint identifier, e.g. 600
     */
    static getPullPushSqueezeClasses(breakpoint, breakpointKey) {
        const classList = [];
        breakpointKey = breakpointKey ? `-at-${breakpointKey}` : '';
        if (breakpoint.pull) {
            classList.push(`mds-layout-grid__col--pull-${breakpoint.pull}${breakpointKey}`);
        }
        if (breakpoint.push) {
            classList.push(`mds-layout-grid__col--push-${breakpoint.push}${breakpointKey}`);
        }
        if (breakpoint.squeeze) {
            classList.push(`mds-layout-grid__col--squeeze-${breakpoint.squeeze}${breakpointKey}`);
        }
        return classList;
    }

    /**
     * Convert a section from a string into an object
     * @param {*} section - the section to convert to an object if it is a string
     */
    static convertSection(section) {
        if (typeof section === 'string') {
            section = {
                id: section,
            };
        }
        return section;
    }

    /**
     * Check if the specified group needs a grid layout
     * @param {*} group - the group to check for layout options
     */
    static needsGridLayout(group) {
        let needsGrid = false;
        if (group.defaults) {
            return true;
        }
        group.sections.forEach(section => {
            // Assume if the section is an object that it has props
            // for setting up the child grid (this is a crude test but ok for most scenarios)
            if (typeof section !== 'string') {
                needsGrid = true;
            }
        });
        return needsGrid;
    }
}
