import mergewith from 'lodash/mergeWith';
import helper from './helper';

function isMwcLoaded() {
    return !!window.mwc;
}

/**
 * Use the logger from mwc if mwc is available.
 * Else, fallback to using window.console
 */
function buildLogger() {
    /* eslint-disable no-console */
    return window.mwc && window.mwc.logger
        ? window.mwc.logger
        : {
              fatal: console.error,
              error: console.error,
              warn: console.warn,
              info: console.info,
              debug: console.debug,
              trace: console.trace,
          };
    /* eslint-enable no-console */
}

// keep this in-sync with ~/packages/mwc-application-helper/src/configuration/configuration.js
function mergeCustomizer(a, b) {
    if (b instanceof Array) {
        return b;
    }
    return undefined;
}

/**
 * Merge configurations
 * @param {Object} defaultConfig Default component configuration
 * @param {Object} providedConfig Configuration provided by the consumer/parent
 */
function mergeConfig(defaultConfig, providedConfig) {
    const merged = mergewith(defaultConfig, providedConfig, mergeCustomizer);
    if (typeof merged === 'object') {
        return merged;
    }

    // config is always expected be an object
    return {};
}

export default {
    name: 'mwc-vue-base-mixin',
    props: ['mwcId', 'config'],
    provide() {
        return {
            injectedConfig: !isMwcLoaded() && this.getComponentConfig(),
        };
    },
    inject: {
        injectedConfig: { default: () => null },
        formatCurrencyCustom: { default: () => null },
        getCurrencySymbolCustom: { default: () => null },
        formatDateCustom: { default: () => null },
        formatNumberCustom: { default: () => null },
        translateCustom: { default: () => null },
        loggerCustom: { default: () => null },
    },
    computed: {
        parsedConfig() {
            const config = this.injectedConfig || this.config;
            return typeof config === 'string' ? JSON.parse(config) : config;
        },
        translate() {
            return (...args) =>
                (
                    this.translateCustom ||
                    (isMwcLoaded() && this.translateWithMwc) ||
                    (key => {
                        const { labels = {}, settings = { languageId: 'en' } } = this.getComponentConfig();
                        const locale = labels[settings.languageId];
                        return (locale && locale[key]) || key;
                    })
                ).apply(this, args);
        },
        formatCurrency() {
            return (...args) =>
                (
                    this.formatCurrencyCustom ||
                    (isMwcLoaded() && this.formatCurrencyWithMwc) ||
                    (x => (x && x.toString()) || x)
                ).apply(this, args);
        },
        getCurrencySymbol() {
            return (...args) =>
                (this.getCurrencySymbolCustom || (isMwcLoaded() && this.getCurrencySymbolWithMwc) || (() => '')).apply(
                    this,
                    args
                );
        },
        formatDate() {
            return (...args) =>
                (
                    this.formatDateCustom ||
                    (isMwcLoaded() && this.formatDateWithMwc) ||
                    (x => (x && x.toString()) || x)
                ).apply(this, args);
        },
        formatNumber() {
            return (...args) =>
                (
                    this.formatNumberCustom ||
                    (isMwcLoaded() && this.formatNumberWithMwc) ||
                    (x => (x && x.toString()) || x)
                ).apply(this, args);
        },
        logger() {
            return this.loggerCustom || this.loggerInternal;
        },
    },
    created() {
        this.$_componentConfig = this.getComponentConfig();
        this.settings = this.$_componentConfig.settings;
        const { languageId, currencyId } = this.settings || {};

        this.loggerInternal = buildLogger();

        const { mwc } = window;
        if (mwc) {
            const FormatterClass = mwc.Formatter;
            this.formatter = new FormatterClass(languageId, this.$_componentConfig.format, currencyId);

            const LabellerClass = mwc.Labeller;
            const intlNamespace = this.getIntlNamespace(this.$_componentConfig);
            this.labeller = new LabellerClass(languageId, this.$_componentConfig.labels, intlNamespace);
        }
    },
    methods: {
        getIntlNamespace(componentConfig) {
            return componentConfig && componentConfig.intlNamespace
                ? componentConfig.intlNamespace
                : this.mwcId || `uid_${this._uid}`; // eslint-disable-line no-underscore-dangle
        },

        getComponentConfig() {
            let result = {};
            const configuration = window.mwc ? window.mwc.configuration : null;
            if (this.mwcId && configuration) {
                result = configuration.getConfig(this.mwcId, this.defaultConfig, this.parsedConfig);
            } else {
                // since no mwc-id is available, consider this to be a non-MWC component
                result = mergeConfig(this.defaultConfig, this.parsedConfig);
            }
            return result;
        },

        getNamespaceId(id) {
            return helper.getNamespaceId(this.mwcId || '', id).replace(/^-/, '');
        },

        getMwcStorageItem(key, preferredStorageMethod = this.settings.preferredStorageMethod) {
            if (isMwcLoaded()) {
                return window.mwc.storageHelper.getItem(this.mwcId, key, preferredStorageMethod);
            }
            return null;
        },

        setMwcStorageItem(key, value, preferredStorageMethod = this.settings.preferredStorageMethod) {
            if (isMwcLoaded()) {
                return window.mwc.storageHelper.setItem(this.mwcId, key, value, preferredStorageMethod);
            }
            return null;
        },

        /**
         * Generate a fully qualified mwc-id for a child inside the current component context
         * @param {string} childIdentifier Intended mwc identifier for the child
         */
        getMwcIdForChild(childIdentifier) {
            return [this.mwcId, childIdentifier].filter(x => x).join('.');
        },

        isComponentSubscribed(dataSourceId, mwcId, element) {
            if (isMwcLoaded() && window.mwc.dataManager.isComponentSubscribed) {
                return window.mwc.dataManager.isComponentSubscribed(dataSourceId, mwcId, element);
            }
            return null;
        },

        // Translation for internationalization
        // interpolations parameter is optional
        translateWithMwc(x, interpolations) {
            return this.labeller.getLabel(x, interpolations);
        },

        // Number formatting for internationalization
        formatNumberWithMwc(x, format, opts) {
            return this.formatter.number(x, format || 'number', opts);
        },

        // Currency formatting for internationalization
        formatCurrencyWithMwc(x, format) {
            return this.formatter.currency(x, format);
        },

        // Get currency symbol for internationalization
        getCurrencySymbolWithMwc(format) {
            return this.formatter.currencySymbolOnly(format);
        },

        // Date formatting for internationalization
        // TODO: Review the default format
        formatDateWithMwc(x, format) {
            return this.formatter.number(x, format || 'date');
        },
    },
};
